<route>
{
  "meta": {
    "requiresAuth": true,
    "breadcrumb": [
          { "title": "Home", "url": "/organisation/dashboard" },
          { "title": "Checkout", "active": true }
      ],
    "title": "Checkout",
    "resource": "tenant",
    "action": ["read"]

  }
}
</route>

<script lang="ts">
import service from '@/services/app-http-client';
import { Loading } from '@/modules/auth/store/auth.service';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { Component, Vue } from 'vue-property-decorator';
import { appModule } from '../../../store/modules/app.module';
import contentModule from '../../../store/modules/content.module';

@Component({
  name: 'checkout',
  layout: 'AppMain',
  metaInfo: {
    title: 'Check-in confirmation',
  },
})
export default class CheckOut extends Vue {
  pricingOrder = ['Free Trial', 'basic', 'standard', 'premium', 'enterprise'];
  loading = true;
  documentCheckbox = false;

  get content() {
    const contentArr: any[] = contentModule.subContent.filter((sub: any) => sub.fields.price !== 0);
    let priceArr: any[] = contentArr.sort((a, b) => {
      return this.pricingOrder.indexOf(a.fields.name.toLowerCase()) - this.pricingOrder.indexOf(b.fields.name.toLowerCase());
    });
    return priceArr;
  }

  get plan() {
    if (this.$route.query.plan) return this.content.find((val) => val?.fields?.name === this.$route.query.plan).fields;
    return { price: this.$route.query.amount, checkInCredits: this.$route.query.credits, name: 'Top up', description: 'Credit top up' };
  }

  get returnURL() {
    return `${appModule.api}/v1/api/billing/nty`;
  }

  payNow() {
    const button = document.getElementsByClassName('wpwl-button') as any;
    console.log(button);
    button[0].click();
  }

  @Loading
  async created() {
    const loading = this.$vs.loading({
      type: 'circles',
      color: 'primary',
      text: 'Loading checkout...',
    });
    let res;
    if (this.$route.query.plan) {
      const plan = this.$route.query.plan as string;
      res = await service.get(`billing/subscription/${plan}`);
    } else {
      res = await service.post(`billing/topup`, {
        amount: parseInt(this.$route.query.amount as string, 10),
        credits: parseInt(this.$route.query.credits as string, 10),
      });
    }
    window.wpwlOptions = {
      style: 'plain',
      requireCvv: true,
      // forceCardHolderEqualsBillingName: true,
      iframeStyles: {
        'card-number-placeholder': {
          'border-color': 'none',
        },
        'cvv-placeholder': {
          'border-color': 'none',
        },
      },
      showCVVHint: true,
      brandDetection: true,
      useSummaryPage: false,
      showLabels: true,
      showPlaceholders: false,
      spinner: {
        color: 'red',
      },
      onReady: () => {
        this.loading = false;
        loading.close();
      },
      onError: () => {
        this.loading = false;
        loading.close();
        this.$swal({
          title: 'Oops! Something went wrong...',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Okay',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.go(-1);
          }
        });
      },
    };
    const peachUri = process.env.VUE_APP_MODE === 'staging' ? 'test.oppwa.com' : 'oppwa.com';

    const script = document.createElement('script');
    script.src = `https://${peachUri}/v1/paymentWidgets.js?checkoutId=${res.data.id}`;
    const tag = document.getElementById('checkout');
    if (tag) tag.appendChild(script);
  }
}
</script>
<template>
  <!-- <div id="checkout">
    <form action="http://localhost:3000/v1/api/billing/nty" class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
  </div> -->
  <!--
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
-->
  <div>
    <main class="max-w-full mx-auto pt-16 pb-24 px-4 sm:px-6 lg:px-8">
      <div class="max-w-7xl mx-auto lg:max-w-none">
        <h1 class="sr-only">Checkout</h1>

        <div class="lg:grid lg:grid-cols-2 xl:gap-x-16">
          <div>
            <!-- Payment -->
            <div id="checkout">
              <h2 class="text-lg font-medium text-gray-900">Payment</h2>
              <fieldset class="mt-4">
                <legend class="sr-only">Payment type</legend>
              </fieldset>
              <form :action="returnURL" class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>

              <div class="border border-gray-200 rounded-lg">
                <div class="py-4 px-4">
                  <div class="py-2">
                    <h3 class="text-gray-900 font-medium text-center">Guaranteed <span class="text-red-600"> SAFE </span> Checkout </h3>
                  </div>
                  <div class="py-2">
                    <a href="https://www.peachpayments.com/" target="_blank">
                      <img class="w-56 mx-auto" src="@/assets/images/Powered_by_Peach_Payments.png" />
                    </a>
                  </div>
                  <div class="py-2 px-10 flex mx-auto">
                    <img class="w-1/3 h-12 px-2" src="@/assets/images/Visa2.jpg" />
                    <img class="w-1/3 h-12 px-2" src="@/assets/images/MASTERCARD.png" />
                    <img class="w-1/3 h-12 px-2" src="@/assets/images/Amex.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Order summary -->
          <div class="mt-10 lg:mt-0">
            <h2 class="text-lg font-medium text-gray-900">Order summary</h2>

            <div class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
              <h3 class="sr-only">Items in your cart</h3>
              <ul role="list" class="divide-y divide-gray-200">
                <li class="flex py-6 px-4 sm:px-6">
                  <div class="flex-1 flex flex-col">
                    <div class="flex">
                      <div class="min-w-0 flex-1">
                        <h4 class="text-sm font-medium text-gray-700 hover:text-gray-800"> {{ plan.name }} plan </h4>
                        <p class="mt-1 text-sm text-gray-500"> {{ plan.description }}</p>
                        <p class="mt-1 text-sm text-gray-500 font-medium"> {{ plan.checkInCredits }} Check-ins</p>
                        <p class="mt-1 text-sm text-gray-500 font-medium"> {{ plan.users }} Users</p>
                      </div>
                    </div>
                    <div class="flex-1 pt-2 flex items-end justify-between">
                      <dt class="text-sm"> </dt>
                      <dd class="text-sm font-medium text-gray-900"> R{{ plan.price }}.00 </dd>
                    </div>
                  </div>
                </li>
              </ul>
              <dl class="border-t border-gray-200 py-6 px-4 space-y-6 sm:px-6">
                <div class="flex items-center justify-between">
                  <dt class="text-sm"> Subtotal </dt>
                  <dd class="text-sm font-medium text-gray-900"> R{{ ((plan.price / 115) * 100).toFixed(2) }} </dd>
                </div>
                <div class="flex items-center justify-between">
                  <dt class="text-sm"> Taxes </dt>
                  <dd class="text-sm font-medium text-gray-900"> R{{ (plan.price - (plan.price / 115) * 100).toFixed(2) }} </dd>
                </div>
                <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt class="text-base font-medium"> Total </dt>
                  <dd class="text-base font-medium text-gray-900"> R{{ plan.price }}.00 </dd>
                </div>
              </dl>

              <div class="border-t border-gray-200 py-6 px-4 sm:px-6">
                <div class="flex text-center py-4">
                  <FormulateInput v-model="documentCheckbox" :active="false" type="checkbox" class="mx-2 font-bold"></FormulateInput>
                  <div class="text-gray-600 font-medium \">
                    I have read & accept the
                    <a target="_blank" href="https://www.checkinbuddy.travel/terms-of-service"> terms of service</a>.
                  </div>
                </div>
                <button
                  class="
                    w-full
                    border border-transparent
                    rounded
                    shadow-sm
                    py-3
                    px-4
                    text-base
                    font-medium
                    text-white
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-red-500
                  "
                  :class="{
                    'bg-gray-500 hover:bg-gray-500 cursor-not-allowed ': !documentCheckbox,
                    'bg-red-600 hover:bg-red-700': documentCheckbox,
                  }"
                  :disabled="!documentCheckbox"
                  @click="payNow"
                  >{{ loading ? 'Loading...' : 'Pay now' }}</button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style lang="css">
.wpwl-wrapper > .wpwl-icon {
  position: absolute;
  top: 0.25em !important;
  right: 0.5625em;
}
</style>
